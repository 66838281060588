






























































import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'
import { Route } from 'vue-router/types/router'

import Confirmation from '@/components/modals/Confirmation.vue'
import ExerciseForm from '@/components/forms/exercise/ExerciseForm.vue'
import ExerciseTaskForm from '@/components/forms/exercise/ExerciseTaskForm.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import ExerciseEditMixin from '@/mixins/manager/ExerciseEditMixin'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import { ExerciseStore } from '@/store/types'
import { convertDateToMSK, convertHoursMinutesToSeconds, parseDateToMilliseconds } from '@/utils/functions'
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    Confirmation,
    ExerciseForm,
    ExerciseTaskForm,
    LinkTabs,
    Parameters,
  },
})
export default class ExercisesItemEdit extends Mixins(ExerciseEditMixin, MasterGroupMixin, NotifyMixin) {
  private get breadcrumbs () {
    return [{ name: 'Домашняя работа', path: '/manager/education/exercises' }]
  }

  private get currentTime() {
    return DictionaryModule.currentTime
  }

  private get exerciseUUID(): string {
    return this.$route.params.exerciseUUID
  }

  private get isSpendingAt() {
    if (this.exerciseMentor) {
      return parseDateToMilliseconds(this.exerciseMentor.spendingAt) < this.currentTime
    }
    return false
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private autoCheck = false

  private isLoading = true

  beforeRouteEnter(to: Route, from: Route, next: any) {
    next((vm: any) => {
      const fromNameRouters = ['manager.dashboard', 'manager.calendar', 'manager.education.exercises']
      if (from.name && fromNameRouters.includes(from.name)) {
        vm.notifyInfo('Заполните домашнее задание.')
      }
    })
  }

  private created () {
    this.$bus.$on('changeAutoCheck', this.handleAutoCheck)
    this.$bus.$on('addMaterials', this.addMaterials)
    DictionaryModule.fetchConstants()
      .then(() => {
        this.fetchExercise()
      })
      .catch(this.notifyError)
    this.syncMasterGroup()
      .then(this.fetchExercise)
  }

  private destroyed() {
    this.$bus.$off('changeAutoCheck', this.handleAutoCheck as any)
    this.$bus.$off('addMaterials', this.addMaterials as any)
  }

  private handleAutoCheck(value: boolean) {
    this.autoCheck = value
  }

  private fetchExercise () {
    ManagerExercisesModule.setExercise(null)
    ManagerCoursesModule.setExercise(null)
    if (!this.exerciseMentor || this.exerciseMentor.uuid !== this.exerciseUUID) {
      this.isLoading = true
      MentorExercisesModule.fetchExercise({
        exerciseUUID: this.$route.params.exerciseUUID,
        masterGroupID: this.currentMasterGroupID,
      })
        .then(() => {
          if (!this.exerciseMentor?.tasks.length) {
            this.handleAddTask('exerciseMentor')
          } else {
            this.syncTasks('exerciseMentor')
          }
          this.autoCheck = this.exerciseMentor ? this.exerciseMentor.autoCheck : false
        })
        .catch((error: any) => {
          this.notifyError(error)
          this.$router.push({ name: 'manager.education.exercises' })
        })
        .finally(() => this.isLoading = false)
    } else {
      this.isLoading = false
      if (!this.exerciseMentor.tasks.length) {
        this.handleAddTask('exerciseMentor')
      } else {
        this.syncTasks('exerciseMentor')
      }
      this.autoCheck = this.exerciseMentor.autoCheck
    }
  }

  // Сохранение контейнера дз
  private handleExerciseSubmit(form: ExerciseStore) {
    if (this.exerciseMentor) {
      const formSubmit = {
        ...form,
        avgExecutionSeconds: convertHoursMinutesToSeconds(form.avgExecutionSeconds as unknown as string),
        deadlineAt: form.deadlineAt ? convertDateToMSK(form.deadlineAt, this.isLocalTimezone) : form.deadlineAt,
        masterIds: form.forAllMasters ? [] : form.masterIds,
        spendingAt: form.spendingAt ? convertDateToMSK(form.spendingAt, this.isLocalTimezone) : form.spendingAt,
      }

      MentorExercisesModule.saveExercise({
        exerciseUUID: this.exerciseMentor.uuid,
        masterGroupID: this.currentMasterGroupID,
        params: formSubmit,
      })
        .then(response => {
          if (response.tasks.length) {
            this.syncTasks('exerciseMentor')
          }
          this.notifySuccess('Основа задания сохранена')
        })
        .catch(this.notifyError)
    }
  }

  private handleExerciseDelete () {
    if (this.exerciseMentor) {
      MentorExercisesModule.deleteExercise({
        exerciseUUID: this.exerciseMentor.uuid,
        masterGroupID: this.currentMasterGroupID,
      })
        .then(() => {
          this.notifySuccess('Задание удалено')
          this.$router.push({ name: 'manager.education.exercises' })
        })
        .catch(this.notifyError)
    }
  }

  private confirmDeleteExercise () {
    this.confirm.open(
      'Удаление задания',
      'Вы действительно хотите полностью удалить выбранное задание?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.handleExerciseDelete)
      .catch(() => {return})
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Редактирование задания',
    }
  }
}
